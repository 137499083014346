/* You can add global styles to this file, and also import other style files */
$primary: #3b82f6;
$secondary: #bfdbfe;
body {
  background-color: #f3f4f6;
}
.k-grid {
  font-family: system-ui, -apple-system, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}
.k-dialog-wrapper {
  z-index: 100000;
}
.k-column-title {
  color: rgba(107, 114, 128);
  font-weight: bold;
}
.k-grid-toolbar {
  background-color: #3b82f6 !important;
}
.k-combobox {
  width: 100% !important;
}
.k-datetimepicker {
  width: 100% !important;
}
.k-dropdown,
.k-dropdowntree {
  width: 100% !important;
}
.k-grid tr.notAttended {
  background-color: #ef4444 !important;
  color: #ffffff;
}
.k-grid tr.notAttended:hover {
  background-color: #dc2626 !important;
}
.k-checkbox:checked,
.k-checkbox.k-checked {
  background-color: #dc2626 !important;
}
.k-grid tr {
  border-color: inherit;
  cursor: pointer;
}
.k-grid tr.dueDate {
  background-color: ffe082 !important;
}
.k-grid tr.dueDate:hover {
  background-color: #ffc107 !important;
}
@import "~@progress/kendo-theme-default/dist/all.scss";
